import React from "react";
import { useNavigate } from "react-router-dom";

import RatingStar from "components/ui/RatingStar/RatingStar";
import { COLORS, STYLE_VALUES } from "utils/constants";
import { StyledAlignCenter } from "styles/common-styled-components";

const Advisor = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/advisors/${data.id}`)}
      style={{
        padding: "3rem",
        border: `1px solid ${COLORS.lightGrey}`,
        marginBottom: "1rem",
        cursor: "pointer",
        boxShadow: STYLE_VALUES.lightBoxShadow,
        display: "flex",
        gap: "4rem",
      }}
    >
      <div
        style={{
          height: "6rem",
          width: "6rem",
          borderRadius: "50%",
          backgroundColor: "#ccc",
        }}
      />
      <div>
        <StyledAlignCenter
          style={{
            marginBottom: "1rem",
          }}
        >
          <h4 style={{ color: COLORS.primary, fontSize: "1.6rem" }}>
            {data.profile?.advisor_name}&nbsp;&nbsp;&nbsp;&nbsp;
          </h4>

          <StyledAlignCenter
            style={{
              gap: "1rem",
            }}
          >
            <RatingStar rating={data.rating.toFixed(1)} />
            <span style={{ fontSize: "1.4rem" }}>
              {data?.rating.toFixed(1)}({data?.number_of_ratings})
            </span>
          </StyledAlignCenter>
        </StyledAlignCenter>

        <div>
          <p>{data.profile?.bio}</p>
          {data.profile?.city}, {data.profile?.state}
        </div>
      </div>
    </div>
  );
};

export default Advisor;
