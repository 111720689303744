import React from "react";
import { PulseLoader } from "react-spinners";

import { StyledButtonBase } from "./Button.styles";
import { ErrorMessage } from "styles/common-styled-components";

const Button = ({ title, isLoading = false, error, ...otherProps }) => {
  return (
    <>
      <StyledButtonBase {...otherProps}>
        {isLoading ? <PulseLoader size={"1.2rem"} color="white" /> : title}
      </StyledButtonBase>
      <ErrorMessage>{error}</ErrorMessage>
    </>
  );
};

export default Button;
