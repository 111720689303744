import React from "react";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Auth from "./pages/Auth/Auth";
import AdvisorMain from "./pages/AdvisorMain/AdvisorMain";
import OTP from "./pages/OTP/OTP";
import Profile from "./pages/Profile/Profile";
import { API_ENDPOINTS } from "./utils/constants";
import { privateAxiosInstance } from "./api-calls/req-res-instance";

const RoutesComp = () => {
  const { userDetails } = useSelector((state) => state.user);

  const handleFetching = () => {
    return privateAxiosInstance.get(API_ENDPOINTS.checkAuth);
  };

  const { isLoading } = useQuery("check-auth", handleFetching, {
    onSuccess: () => {
      sessionStorage.setItem("auth-checked", true);
    },
    enabled:
      Boolean(userDetails?.mobile_number) &&
      !sessionStorage.getItem("auth-checked"),
  });

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <Routes>
      <Route index element={<Home />} />
      <Route path={"/advisors/:id"} element={<AdvisorMain />} />
      <Route path={"/profile"} element={<Profile />} />

      <Route path="/auth" element={<Auth />}>
        <Route path="login" element={<Login />} />
        <Route path="otp" element={<OTP />} />
      </Route>
    </Routes>
  );
};

export default RoutesComp;
