import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { Formik } from "formik";

import RatingStar from "components/ui/RatingStar/RatingStar";
import Button from "components/ui/Button/Button";
import ListRenderer from "components/utility/ListRenderer";
import PageContainer from "components/ui/PageContainer/PageContainer";
import { privateAxiosInstance } from "api-calls/req-res-instance";
import { API_URL } from "settings";
import { API_ENDPOINTS, COLORS, GENERIC_ERROR_RES_MSG } from "utils/constants";
import { Rating } from "@mui/material";
import DataRenderer from "components/utility/DataRenderer";

import {
  StyledAlignCenter,
  StyledLink,
  TextArea,
  Label,
} from "styles/common-styled-components";
import Profile from "./_Profile";

const initialErrorState = {
  profileData: "",
  contact: "",
  reviews: "",
  createReview: "",
  myReview: "",
};

const AdvisorMain = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userDetails } = useSelector((state) => state.user);

  const [errors, setErrors] = useState(initialErrorState);

  //// API CALL

  // POST REVIEW
  const handleCreateReview = (data) => {
    return privateAxiosInstance.post(
      `${API_URL}/advisors/${id}/reviews/`,
      data
    );
  };

  const { isLoading: isPostingReview, mutate: postReview } = useMutation(
    handleCreateReview,
    {
      onSuccess: () => {
        setErrors(initialErrorState);
      },
      onError: () => {
        setErrors((prev) => {
          return { ...prev, createReview: GENERIC_ERROR_RES_MSG };
        });
      },
    }
  );

  //// API CALL END

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <PageContainer>
      {/* ADVISOR */}
      <div style={{ marginBottom: "2rem" }}>
        <DataRenderer
          apiEndpoint={`${API_ENDPOINTS.advisors}${id}/`}
          queryName={`advisor-${id}`}
          renderData={(advisorDetail) => (
            <Profile advisorDetail={advisorDetail} />
          )}
        />
      </div>

      {/* CONTACT */}
      <div style={{ marginBottom: "4rem" }}>
        {userDetails?.mobile_number ? (
          <DataRenderer
            queryName={`contact-${id}`}
            privateRequest
            apiEndpoint={`${API_ENDPOINTS.advisors}${id}/contact/`}
            renderData={(advisorContact) => (
              <p>Contact: {advisorContact?.data?.contact}</p>
            )}
          />
        ) : (
          <Button
            title="GET CONTACT"
            onClick={() => {
              navigate("/auth/login", {
                state: {
                  fromLocation: `/advisors/${id}`,
                },
              });
            }}
          />
        )}
      </div>

      {/* POST REVIEW */}
      <div>
        {userDetails?.mobile_number ? (
          <DataRenderer
            queryName={`review-${id}`}
            privateRequest
            apiEndpoint={`${API_ENDPOINTS.advisors}${id}/reviews/my-review/`}
            renderData={(myReview) => {
              return myReview?.data?.review_text ? (
                <>
                  <h4>Your Review</h4>

                  <div
                    style={{
                      border: `1px solid ${COLORS.lightGrey}`,
                      padding: "1rem",
                      margin: "2rem 0",
                    }}
                  >
                    <StyledAlignCenter
                      style={{
                        gap: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <span>{myReview?.data?.rating}</span>
                      <RatingStar rating={myReview?.data?.rating} />
                    </StyledAlignCenter>
                    <p>{myReview?.data?.review_text}</p>
                  </div>
                </>
              ) : (
                <div style={{ width: "60rem" }}>
                  <Formik
                    initialValues={{
                      review_text: "",
                      rating: "",
                    }}
                    onSubmit={postReview}
                  >
                    {({ values, handleSubmit, handleChange }) => {
                      return (
                        <form
                          onSubmit={handleSubmit}
                          style={{
                            padding: "1rem",
                            border: `1px solid ${COLORS.lightGrey}`,
                            margin: "2rem 0",
                          }}
                        >
                          <Label>Rating</Label>
                          <Rating
                            size="large"
                            value={values.rating}
                            onChange={handleChange("rating")}
                          />
                          <Label>Review</Label>
                          <TextArea
                            value={values.review_text}
                            onChange={handleChange("review_text")}
                          />
                          <Button
                            type="submit"
                            title="Submit"
                            isLoading={isPostingReview}
                            loadingText="Creating Review..."
                            error={errors.createReview}
                          />
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              );
            }}
          />
        ) : (
          <div style={{ marginBottom: "1rem" }}>
            <StyledLink
              to={"/auth/login"}
              state={{ fromLocation: `/advisors/${id}` }}
            >
              Login
            </StyledLink>{" "}
            to post your review
          </div>
        )}
      </div>

      {/* REVIEWS */}
      <h3 style={{ marginBottom: "2rem" }}>Reviews</h3>
      <div>
        <DataRenderer
          queryName={"reviews"}
          apiEndpoint={`${API_ENDPOINTS.advisors}${id}/reviews/list/`}
          renderData={(advisorReviews) => {
            return (
              <>
                {advisorReviews.data.length === 0 ? (
                  <p>No Reviews</p>
                ) : (
                  <ListRenderer
                    items={advisorReviews.data}
                    renderItem={(curr) => (
                      <div
                        style={{
                          border: "1px solid #ccc",
                          padding: "2rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <StyledAlignCenter
                          style={{
                            gap: "1rem",
                            marginBottom: "2rem",
                          }}
                        >
                          <span>{curr.rating}</span>
                          <RatingStar rating={curr.rating} />
                        </StyledAlignCenter>
                        <span>{curr.review_text}</span>
                      </div>
                    )}
                  />
                )}
              </>
            );
          }}
        />
      </div>
    </PageContainer>
  );
};

export default AdvisorMain;
