import { ButtonBase } from "@mui/material";
import styled, { css } from "styled-components";
import { COLORS } from "utils/constants";

const normal = css`
  background-color: ${COLORS.secondary} !important;
  color: ${COLORS.lightGrey} !important;
`;

const outlined = css`
  background: none !important;
  border: 1px solid ${COLORS.secondary} !important;
  color: ${COLORS.secondary} !important;
`;

const danger = css`
  background-color: ${COLORS.danger} !important;
`;

const variants = {
  outlined,
  danger,
  normal,
};

export const StyledButtonBase = styled(ButtonBase)`
  padding: 10px 20px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  max-width: 34rem;
  ${({ variant = "normal" }) => variants[variant]}
`;
