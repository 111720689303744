import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";

import SvgColorLogo from "assets/logos/color-logo- no-background.svg";
import { STYLE_VALUES } from "utils/constants";
import { createPortal } from "react-dom";
import Button from "components/ui/Button/Button";
import { privateAxiosInstance } from "api-calls/req-res-instance";
import { API_URL } from "settings";
import { addUserDetails } from "redux/user/user-slice";
import { GENERIC_ERROR_RES_MSG } from "utils/constants";
import {
  StyledAlignCenter,
  StyledLink,
  StyledSpaceBetween,
} from "styles/common-styled-components";
import Image from "components/ui/Image/Image";

const initialErrorState = {
  logout: "",
};

const Navbar = () => {
  const { userDetails } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState(initialErrorState);

  //// API CALLS

  // Logout
  const handleLogout = () => {
    return privateAxiosInstance.post(`${API_URL}/logout/`);
  };

  const { mutate: logout, isLoading: isLoggingOut } = useMutation(
    handleLogout,
    {
      onSuccess: () => {
        setErrors(initialErrorState);
        dispatch(addUserDetails({}));
        storage.removeItem("persist:root");
        sessionStorage.setItem("auth-checked", false);
        navigate("/auth/login");
      },
      onError: () => {
        setErrors((prev) => {
          return {
            ...prev,
            logout: GENERIC_ERROR_RES_MSG,
          };
        });
        return new Error("something went wrong");
      },
    }
  );

  return createPortal(
    <StyledSpaceBetween
      style={{
        boxShadow: STYLE_VALUES.boxShadow,
        padding: "1rem 10rem",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        zIndex: 1000,
      }}
    >
      {/* LOGO */}
      <Image
        src={SvgColorLogo}
        alt="Advisor Logo"
        size={"20rem"}
        onClick={() => navigate("/")}
      />

      {/* RIGHT */}
      <StyledAlignCenter style={{ gap: "2rem" }}>
        {userDetails?.mobile_number ? (
          <>
            <Button
              title="Logout"
              onClick={logout}
              isLoading={isLoggingOut}
              loadingText="Logging Out"
              error={errors.logout}
            />
          </>
        ) : (
          <>
            <Button title="I'm Advisor" />
            <StyledLink to={"/auth/login"}>Login/Register</StyledLink>
          </>
        )}
      </StyledAlignCenter>
    </StyledSpaceBetween>,
    document.body
  );
};

export default Navbar;
