import { GENERIC_ERROR_RES_MSG } from "./constants";

export function mapServerErrorsToLocal(errorObject, initialErrorsState) {
  const newErrors = { ...initialErrorsState };
  if (errorObject.response?.data?.detail) {
    newErrors["general"] = errorObject.response?.data?.detail;
  } else if (errorObject.response && errorObject.response.data) {
    const serverErrors = errorObject.response.data;
    // Map errors dynamically based on field names
    Object.keys(serverErrors).forEach((field) => {
      if (initialErrorsState.hasOwnProperty(field)) {
        newErrors[field] = serverErrors[field][0];
      }
    });
  } else {
    newErrors["general"] = GENERIC_ERROR_RES_MSG;
  }

  return newErrors;
}
