const { css } = require("styled-components");

export const centering = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const space_between = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const align_center = css`
  display: flex;
  align-items: center;
`;
