import React from "react";
import { motion } from "framer-motion";

import ANIMATION_STYLES from "styles/animations";

const PageContainer = ({ children }) => {
  return (
    <motion.div
      initial={ANIMATION_STYLES.opacity.initial}
      animate={ANIMATION_STYLES.opacity.animate}
      exit={ANIMATION_STYLES.opacity.exit}
    >
      {children}
    </motion.div>
  );
};

export default PageContainer;
