import React from "react";

import { InputWrapper, InputContainer, Icon, Input } from "./InputField.styles";
import { ErrorMessage, Label } from "styles/common-styled-components";

const InputField = ({
  label,
  error,
  appendLeft,
  appendRight,
  noSpin,
  ...inputProps
}) => {
  const hasError = Boolean(error);
  const appendPosition = appendLeft ? "left" : appendRight ? "right" : "";

  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <InputContainer>
        {appendLeft && <Icon position="left">{appendLeft}</Icon>}
        <Input
          noSpin={noSpin}
          hasError={hasError}
          appendPosition={appendPosition}
          {...inputProps}
        />
        {appendRight && <Icon position="right">{appendRight}</Icon>}
      </InputContainer>
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

export default InputField;
