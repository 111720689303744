import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "components/ui/Button/Button";
import { API_URL } from "settings";
import { useMutation } from "react-query";
import { addUserDetails } from "redux/user/user-slice";
import { useDispatch } from "react-redux";
import { axiosInstance } from "api-calls/req-res-instance";
import PageContainer from "components/ui/PageContainer/PageContainer";
import {
  ErrorMessage,
  StyledAlignCenter,
  StyledCentering,
  StyledLink,
} from "styles/common-styled-components";
import { COLORS, GENERIC_ERROR_RES_MSG, STYLE_VALUES } from "utils/constants";
import OtpInput from "react-otp-input";
import Timer from "components/ui/Timer/Timer";
import { mapServerErrorsToLocal } from "utils/functions";

const initialErrorState = { general: "", otp: "" };
const OTP = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(initialErrorState);
  const [showResendOTP, setshowResendOTP] = useState(false);

  const fromLocation = location.state?.fromLocation || "/";

  // API CALLS

  const handleSubmitMobileNumber = (payload) => {
    return axiosInstance.post(`${API_URL}/consultant/verify/`, {
      mobile_number: location.state.mobile_number,
      otp: payload.otp,
    });
  };

  const { mutate: mutateLogin, isLoading: isLoggingIn } = useMutation(
    (payload) => handleSubmitMobileNumber(payload),
    {
      onSuccess: async () => {
        dispatch(
          addUserDetails({ mobile_number: location.state.mobile_number })
        );
        navigate(fromLocation, { replace: true });
      },
      onError: (err) => {
        const newErrors = mapServerErrorsToLocal(err, initialErrorState);
        setErrors(newErrors);
      },
    }
  );

  ///// API CALL END

  const handleForm = (values) => {
    mutateLogin({
      otp: values.otp,
    });
  };

  return (
    <PageContainer>
      <StyledCentering style={{ flexDirection: "column", gap: "2rem" }}>
        <h1 style={{}}>Submit OTP</h1>

        <StyledCentering>
          <div
            style={{
              display: "flex",
              boxShadow: STYLE_VALUES.boxShadow,
            }}
          >
            <StyledCentering
              style={{
                width: "40rem",
                height: "40rem",
                backgroundColor: "#f5f5f5",
                padding: "4rem",
                color: "#888",
                fontSize: "2rem",
              }}
            >
              <h2>You're just one step away!</h2>
            </StyledCentering>
            <StyledCentering style={{ padding: "4rem" }}>
              <Formik
                innerRef={ref}
                initialValues={{
                  otp: "",
                }}
                onSubmit={handleForm}
              >
                {({ values, handleSubmit, handleChange }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <p style={{ width: "30rem" }}>
                        Please enter one-time verification code to your mobile
                        number.
                      </p>
                      <p>
                        Entered a wrong number?{" "}
                        <StyledLink
                          to="/auth/login"
                          state={{
                            editPhone: true,
                          }}
                          style={{
                            color: COLORS.secondary,
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Edit
                        </StyledLink>
                      </p>
                      <div style={{ maxWidth: "34rem" }}>
                        <OtpInput
                          shouldAutoFocus={true}
                          value={values.otp}
                          onChange={handleChange("otp")}
                          numInputs={6}
                          inputStyle={{
                            width: "5rem",
                            height: "5rem",
                            borderRadius: "6px",
                            border: `1px solid #ccc`,
                            fontSize: "2.4rem",
                            fontWeight: "800",
                            color: "#333",
                          }}
                          focusStyle={{
                            outlineColor: COLORS.secondary,
                          }}
                          containerStyle={{
                            display: "flex",
                            gap: "1rem",
                            marginBottom: "2rem",
                          }}
                        />
                        {errors.otp ? (
                          <ErrorMessage>{errors.otp}</ErrorMessage>
                        ) : null}
                      </div>

                      <Button
                        title="SUBMIT"
                        isLoading={isLoggingIn}
                        loadingText="Logging In"
                        error={errors.general}
                        type="submit"
                        style={{ width: "100%" }}
                      />
                      {showResendOTP ? (
                        <p
                          style={{
                            color: COLORS.secondary,
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Resend OTP
                        </p>
                      ) : (
                        <StyledAlignCenter>
                          <p style={{ marginBottom: 0 }}>Resend OTP in&nbsp;</p>
                          <Timer
                            numberOfMinutes={3}
                            onExpire={() => setshowResendOTP(true)}
                          />
                        </StyledAlignCenter>
                      )}
                    </form>
                  );
                }}
              </Formik>
            </StyledCentering>
          </div>
        </StyledCentering>
      </StyledCentering>
    </PageContainer>
  );
};

export default OTP;
