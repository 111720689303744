import { createGlobalStyle } from "styled-components";
import { COLORS } from "utils/constants";

const GlobalStyles = createGlobalStyle`
* {
margin: 0;
padding: 0;
box-sizing: border-box;
min-height: 0;
min-width: 0;
}

body {
color: ${COLORS.darkGrey};
font-family: 'Poppins', sans-serif;
font-size: 1.4rem;
}


html {
font-size: 62.5%;

p {
    margin-bottom: 2rem;
}

@media (max-width:600px){
    font-size: 56%;
}
@media (max-width:1024px){
    font-size: 57%;
}
@media (max-width:1136px){
    font-size: 58%;
}
@media (min-width:1600px){
    font-size: 80%;
}
}
`;

export default GlobalStyles;
