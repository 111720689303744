import React from "react";

const Image = ({ src, alt, size, onClick }) => {
  return (
    <div
      style={{ height: "auto", width: size, cursor: "pointer" }}
      onClick={onClick}
    >
      <img
        src={src}
        alt={alt}
        style={{ objectFit: "contain", width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default Image;
