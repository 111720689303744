import styled, { css } from "styled-components";

import { COLORS } from "utils/constants";
export const no_spin = css`
  -moz-appearance: textfield; /* Firefox */

  /* Remove default arrows in WebKit browsers (Chrome, Safari) */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  max-width: 34rem;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.span`
  position: absolute;
  ${({ position }) => (position === "left" ? "left: 10px;" : "right: 10px;")}
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 1.6rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1.6rem;
  border: 1px solid ${({ hasError }) => (hasError ? COLORS.danger : "#ccc")};
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  ${({ noSpin }) => (noSpin ? no_spin : null)}

  &:focus{
    border: 1px solid ${COLORS.secondary};
  } 

  padding-${({ appendPosition }) =>
    appendPosition === "left" ? "left" : "right"}: ${({ appendPosition }) =>
  appendPosition ? "40px" : "10px"}; /* Adjust based on icon size *
`;
