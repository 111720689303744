import React, { useState } from "react";

import { privateAxiosInstance } from "api-calls/req-res-instance";
import { useMutation, useQuery } from "react-query";
import { GENERIC_ERROR_RES_MSG } from "utils/constants";
import Table from "components/ui/Table/Table";
import { Formik } from "formik";
import Button from "components/ui/Button/Button";

const Profile = () => {
  const initialErrorState = {
    getProfile: "",
    updateProfile: "",
    location: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const [location, setLocation] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [fetchingLocation, setFetchingLocation] = useState(false);

  const [profile, setProfile] = useState({
    advisor_name: "",
    bio: "",
    line1: "",
    line2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  const handleFetchProfile = () => {
    return privateAxiosInstance.get(`/advisor/profile/`);
  };

  // ADVISOR PROFILE

  const {
    isLoading,
    data: advisorProfile,
    isRefetching,
    isFetched: isProfileFetched,
  } = useQuery("advisor-profile", handleFetchProfile, {
    onSuccess: (res) => {
      setErrors(initialErrorState);
      setProfile(res.data);
    },
    onError: () => {
      setErrors((prev) => {
        return {
          ...prev,
          getProfile: GENERIC_ERROR_RES_MSG,
        };
      });
    },
  });

  const handleUpdateProfile = (data) => {
    return privateAxiosInstance.patch(`/advisor/profile/`, data);
  };

  const { isLoading: isUpdatingProfile, mutate } = useMutation(
    "advisor-profile",
    handleUpdateProfile,
    {
      onSuccess: () => {
        setErrors(initialErrorState);
      },
      onError: () => {
        setErrors((prev) => {
          return {
            ...prev,
            updateProfile: GENERIC_ERROR_RES_MSG,
          };
        });
      },
    }
  );

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();

      const detailedAddress = {
        line1: `${data.address.suburb}, ${data.address.city_district}` || "",
        line2: `${data.address.county} ${data.address.state_district}` || "",
        city: data.address.city || data.address.town || "",
        state: data.address.state || "",
        pincode: data.address.postcode || "",
        country: data.address.country || "",
        lat_long: `${latitude}-${longitude}`,
      };

      return detailedAddress;
    } catch (error) {
      throw error;
    }
  };

  const getLocation = async () => {
    setFetchingLocation(true);
    if (navigator.geolocation) {
      try {
        const position = await getCurrentPosition();
        const address = await reverseGeocode(
          position.coords.latitude,
          position.coords.longitude
        );
        setLocation(address);
      } catch (error) {
        console.error("Error getting location:", error);
        setErrors((prev) => {
          return {
            ...prev,
            updateProfile: "Error getting location. Please try again.",
          };
        });
      } finally {
        setFetchingLocation(false);
      }
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          updateProfile: "Geolocation is not supported by your browser.",
        };
      });
    }
  };

  const geocode = async (address) => {
    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${address}&key=36f977f9d433466180784aaa32e57bfd`
      );
      const data = await response.json();

      if (data && data.length > 0) {
        const firstResult = data[0];
        console.log(parseFloat(firstResult.lat), parseFloat(firstResult.lon));
      } else {
        console.error("No results found.");
      }
    } catch (error) {
      console.error("Error during geocoding:", error);
    }
  };

  return (
    <div>
      {isLoading || isRefetching ? (
        <>Loading...</>
      ) : errors.getProfile ? (
        <>{errors.getProfile}</>
      ) : (
        <>
          <h4>Personal INFO</h4>
          <Table
            data={{
              "Advisor Name": advisorProfile.data.advisor_name,
              Bio: advisorProfile.data.bio,
            }}
          />
          <h4>Location INFO</h4>
          <Table
            data={{
              "Line 1": advisorProfile.data.line1,
              "Line 2": advisorProfile.data.line2,
              City: advisorProfile.data.city,
              State: advisorProfile.data.state,
              Country: advisorProfile.data.country,
              pincode: advisorProfile.data.pincode,
            }}
          />
          <h3>Update Profile</h3>
          <Formik
            initialValues={{
              advisor_name: profile.advisor_name,
              bio: profile.bio,
            }}
            onSubmit={mutate}
          >
            {({ values, handleSubmit, handleChange }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <label>Advisor Name</label>
                  <input
                    style={{ display: "block" }}
                    value={values.advisor_name}
                    onChange={handleChange("advisor_name")}
                  />
                  <label>Bio</label>
                  <textarea
                    style={{ display: "block" }}
                    value={values.bio}
                    onChange={handleChange("bio")}
                  />
                  <Button
                    title="UPDATE PROFILE"
                    isLoading={isUpdatingProfile}
                    loadingText="Updating Profile..."
                    error={errors.updateProfile}
                  />
                </form>
              );
            }}
          </Formik>
          <h3>Update Location</h3>
          <Formik
            initialValues={{
              line1: profile.line1,
              line2: profile.line2,
              city: profile.city,
              state: profile.state,
              country: profile.country,
              pincode: profile.pincode,
            }}
            onSubmit={(v) =>
              geocode(
                `${v.line1}, ${v.line2}, ${v.city}, ${v.state}, ${v.country}, ${v.pincode}`
              )
            }
          >
            {({ values, handleSubmit, handleChange }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <label>Line 1</label>
                  <input
                    style={{ display: "block" }}
                    value={values.line1}
                    onChange={handleChange("line1")}
                  />
                  <label>Line 2</label>
                  <input
                    style={{ display: "block" }}
                    value={values.line2}
                    onChange={handleChange("line2")}
                  />
                  <label>City</label>
                  <input
                    style={{ display: "block" }}
                    value={values.city}
                    onChange={handleChange("city")}
                  />
                  <label>State</label>
                  <input
                    style={{ display: "block" }}
                    value={values.state}
                    onChange={handleChange("state")}
                  />
                  <label>Country</label>
                  <input
                    style={{ display: "block" }}
                    value={values.country}
                    onChange={handleChange("country")}
                  />
                  <label>Pincode</label>
                  <input
                    style={{ display: "block" }}
                    value={values.pincode}
                    onChange={handleChange("pincode")}
                  />

                  <Button
                    title={"UPDATE LOCATION"}
                    isLoading={isUpdatingProfile}
                    loadingText={"UPDATING LOCATION"}
                    type="submit"
                    error={errors.updateProfile}
                  />
                </form>
              );
            }}
          </Formik>

          <Button
            title="Fetch Location"
            onClick={getLocation}
            loadingText="Fetching Location"
            isLoading={fetchingLocation}
          />
        </>
      )}
    </div>
  );
};

export default Profile;
