import React from "react";
import { StarHalf, StarOutline, StarRateSharp } from "@mui/icons-material";

const Star = ({ rating }) => {
  const renderStars = () => {
    const fullStars = Math.floor(rating);
    const decimalPart = rating % 1;

    const stars = [];
    for (let i = 1; i <= fullStars; i++) {
      stars.push(<StarRateSharp style={{ fontSize: "2rem" }} key={i} />); // Full star
    }

    if (decimalPart > 0.4) {
      stars.push(<StarHalf style={{ fontSize: "2rem" }} key={decimalPart} />); // Half star
    }

    const remainingStars = 5 - stars.length;
    for (let i = 1; i <= remainingStars; i++) {
      stars.push(<StarOutline style={{ fontSize: "2rem" }} key={i + 5} />); // Blank star
    }

    return stars;
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1px" }}>
      {renderStars()}
    </div>
  );
};

export default Star;
