import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "utils/constants";
import { align_center, centering, space_between } from "./common-styles";

export const StyledLink = styled(Link)`
  &:link,
  &:visited,
  &:active {
    color: ${COLORS.primary};
  }
`;

export const StyledCentering = styled.div`
  ${centering}
`;

export const StyledAlignCenter = styled.div`
  ${align_center}
`;

export const StyledSpaceBetween = styled.div`
  ${space_between}
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 1.6rem;
  border: 1px solid ${({ hasError }) => (hasError ? COLORS.danger : "#ccc")};
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease; 
  box-sizing: border-box;

  &:focus {
    border: 1px solid ${COLORS.secondary};
  }
`;

export const ErrorMessage = styled.div`
  color: ${COLORS.danger};
  font-size: 14px;
  margin: 5px 0;
`;

export const Label = styled.label`
  display: block;
  font-size: 1.6rem;
  margin-bottom: 6px;
`;
