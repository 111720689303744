export const GENERIC_ERROR_RES_MSG = "Something went wrong! Please try again.";

export const API_ENDPOINTS = {
  checkAuth: "/check-auth/",
  advisors: "/advisors/",
};

export const STYLE_VALUES = {
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  lightBoxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px",
};

export const COLORS = {
  primary: "#b83b5e",
  secondary: "#32a287",
  danger: "#e61515",
  darkGrey: "#333",
  mediumGrey: "#666",
  lightGrey: "#f5f5f5",
  secondaryLightGrey: "#aaa",
};
