import React, { useState } from "react";
import { PulseLoader } from "react-spinners";

import { StyledButtonBase } from "./SwitchButton.styles";
import { ErrorMessage } from "styles/common-styled-components";
import { COLORS } from "utils/constants";

const SwitchButton = ({
  title,
  isLoading = false,
  error,
  isActive,
  ...otherProps
}) => {
  return (
    <>
      <StyledButtonBase isActive={isActive} {...otherProps}>
        {isLoading ? (
          <PulseLoader size={"1.2rem"} color={COLORS.secondaryLightGrey} />
        ) : (
          title
        )}
      </StyledButtonBase>
      <ErrorMessage>{error}</ErrorMessage>
    </>
  );
};

export default SwitchButton;
