import React from "react";

import RatingStar from "components/ui/RatingStar/RatingStar";

import { StyledAlignCenter } from "styles/common-styled-components";
import { COLORS } from "utils/constants";

const Profile = ({ advisorDetail }) => {
  return (
    <div>
      <div
        style={{
          height: "14rem",
          width: "14rem",
          backgroundColor: "#ccc",
          marginBottom: "2rem",
        }}
      />
      <div>
        <StyledAlignCenter
          style={{
            marginBottom: "1rem",
          }}
        >
          <h4 style={{ color: COLORS.primary, fontSize: "1.6rem" }}>
            {advisorDetail.data.profile.advisor_name}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </h4>

          <StyledAlignCenter
            style={{
              gap: "1rem",
            }}
          >
            <RatingStar rating={advisorDetail.data.rating.toFixed(1)} />
            <span style={{ fontSize: "1.4rem" }}>
              {advisorDetail.data.rating.toFixed(1)}(
              {advisorDetail.data.number_of_ratings})
            </span>
          </StyledAlignCenter>
        </StyledAlignCenter>

        <div>
          <p>{advisorDetail.data.profile.bio}</p>
          {advisorDetail.data.profile.city}, {advisorDetail.data.profile.state}
        </div>
      </div>
    </div>
  );
};

export default Profile;
