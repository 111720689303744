const ANIMATION_STYLES = {
  scale: {
    initial: {
      scale: 0,
    },
    animate: {
      scale: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      scale: 0,
    },
  },
  slideFromBottom: {
    initial: {
      y: 300,
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      y: 300,
    },
  },
  opacity: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
    },
  },

  slide: {
    initial: { x: window.innerWidth },
    animate: {
      x: 0,
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      x: -window.innerWidth,
    },
  },
};

export default ANIMATION_STYLES;
