import React, { useState, useRef, useEffect } from "react";

import useOutsideClick from "hooks/useOutsideClick";
import { COLORS } from "utils/constants";
import ListRenderer from "components/utility/ListRenderer";

import {
  StyledSelect,
  SelectButton,
  ArrowIcon,
  OptionsContainer,
  Option,
} from "./Select.styles";

const CustomSelect = ({ options, value, onChange = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const optionsContainerRef = useRef(null);
  const selectRef = useRef(null);

  // Use the useOutsideClick hook to close options when clicking outside
  useOutsideClick(selectRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (highlightedIndex !== -1 && optionsContainerRef.current) {
      const highlightedOption =
        optionsContainerRef.current.children[highlightedIndex];
      if (highlightedOption) {
        highlightedOption.scrollIntoView({
          block: "nearest",
          inline: "nearest",
        });
      }
    }
  }, [highlightedIndex]);

  const handleSelect = (selectedValue) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && highlightedIndex < options.length - 1) {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => prevIndex + 1);
    } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => prevIndex - 1);
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      const selectedOption = options[highlightedIndex];
      handleSelect(selectedOption.value);
    } else if (
      e.key === "ArrowDown" &&
      highlightedIndex === options.length - 1
    ) {
      e.preventDefault();
      setHighlightedIndex(0);
    }
  };

  return (
    <StyledSelect ref={selectRef}>
      <SelectButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
      >
        {options.find((option) => option.value === value)?.label || "Select"}
        <ArrowIcon isOpen={isOpen} />
      </SelectButton>
      <OptionsContainer isOpen={isOpen} ref={optionsContainerRef}>
        <ListRenderer
          items={options}
          customKeyPropName={"value"}
          renderItem={(option, index) => (
            <Option
              onClick={() => handleSelect(option.value)}
              onMouseEnter={() => setHighlightedIndex(index)}
              style={{
                backgroundColor:
                  highlightedIndex === index ? COLORS.lightGrey : "transparent",
              }}
            >
              {option.label}
            </Option>
          )}
        />
      </OptionsContainer>
    </StyledSelect>
  );
};

export default CustomSelect;
