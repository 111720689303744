import { ButtonBase } from "@mui/material";

import styled, { css } from "styled-components";
import { COLORS } from "utils/constants";

const active = css`
  background-color: ${COLORS.secondary} !important;
  color: ${COLORS.lightGrey} !important;
`;

const inactive = css`
  background: none !important;
  border: 1px solid ${COLORS.secondaryLightGrey} !important;
  color: ${COLORS.mediumGrey} !important;
`;

const variants = {
  active,
  inactive,
};

export const StyledButtonBase = styled(ButtonBase)`
  padding: 10px 20px !important;
  border-radius: 100px !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  max-width: 34rem;
  ${({ isActive }) => (isActive ? variants["active"] : variants["inactive"])}
`;
