import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "components/ui/Button/Button";
import { API_URL } from "settings";
import { useMutation } from "react-query";
import { axiosInstance } from "api-calls/req-res-instance";
import InputField from "components/ui/InputField/InputField";
import PageContainer from "components/ui/PageContainer/PageContainer";
import { StyledCentering } from "styles/common-styled-components";
import { GENERIC_ERROR_RES_MSG, STYLE_VALUES } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";

const initialErrorState = { mobile_number: "", general: "" };
const Login = () => {
  const ref = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const [errors, setErrors] = useState(initialErrorState);

  const fromLocation = location.state?.fromLocation || "/";

  // API CALLS

  const handleSubmitMobileNumber = (payload) => {
    return axiosInstance.post(`${API_URL}/consultant/submit/`, {
      mobile_number: payload.mobile_number,
    });
  };

  const { mutate: mutateLogin, isLoading: isSendingOtp } = useMutation(
    (payload) => handleSubmitMobileNumber(payload),
    {
      onSuccess: async () => {
        setErrors(initialErrorState);
        navigate("/auth/otp", {
          state: {
            fromLocation,
            mobile_number: ref.current.values.mobile_number,
          },
        });
      },
      onError: (err) => {
        const newErrors = mapServerErrorsToLocal(err, initialErrorState);
        setErrors(newErrors);
      },
    }
  );

  ///// API CALL END

  const handleForm = (values) => {
    mutateLogin({
      mobile_number: values.mobile_number,
    });
  };

  return (
    <PageContainer>
      <StyledCentering style={{ flexDirection: "column", gap: "2rem" }}>
        <h1 style={{}}>Login/Register</h1>

        <div
          style={{
            display: "flex",
            boxShadow: STYLE_VALUES.boxShadow,
          }}
        >
          <StyledCentering
            style={{
              width: "40rem",
              height: "40rem",
              backgroundColor: "#f5f5f5",
              padding: "4rem",
              color: "#777",
              fontSize: "2rem",
            }}
          >
            <h2>
              Explore tailored mutual fund advice and financial expertise.
            </h2>
          </StyledCentering>
          <StyledCentering style={{ padding: "4rem", width: "45rem" }}>
            <Formik
              innerRef={ref}
              initialValues={{
                mobile_number: "",
              }}
              onSubmit={handleForm}
            >
              {({ values, handleSubmit, handleChange }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <InputField
                      appendLeft={<>91+</>}
                      label={"Mobile Number"}
                      value={values.mobile_number}
                      onChange={handleChange("mobile_number")}
                      type="number"
                      noSpin
                      autoFocus
                      error={errors.mobile_number}
                    />
                    <Button
                      type="submit"
                      title="SEND OTP"
                      isLoading={isSendingOtp}
                      loadingText="Sending OTP..."
                      error={errors.general}
                      style={{ width: "100%" }}
                    />
                  </form>
                );
              }}
            </Formik>
          </StyledCentering>
        </div>
      </StyledCentering>
    </PageContainer>
  );
};

export default Login;
