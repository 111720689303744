import axios from "axios";
import storage from "redux-persist/lib/storage";

import { API_URL } from "settings";

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const privateAxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

privateAxiosInstance.interceptors.response.use(
  (response) => {
    // If the request was successful, just return the response
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Check if the error is due to an expired token
    if (
      error.response &&
      (error.response.data.detail === "Access token is invalid or expired" ||
        error.response.data.detail ===
          "Authentication credentials were not provided.")
    ) {
      try {
        // Perform token refresh by calling your refresh token endpoint
        await axiosInstance.post(`${API_URL}/token/refresh/`);
        return privateAxiosInstance(originalRequest);
      } catch (refreshError) {
        // If token refresh fails, handle the error as needed (e.g., redirect to login)
        if (
          refreshError.response?.data?.detail ===
            "Refresh token not found in cookie." ||
          refreshError.response?.data.detail === "Token is invalid or expired"
        ) {
          storage.removeItem("persist:root");
          sessionStorage.setItem("auth-checked", false);
          try {
            await axiosInstance.post(`${API_URL}/dev-logout/`);
          } catch (error) {
            throw error;
          }
          window.location.replace("/");
        }
        // You might want to redirect to the login page or do other error handling here
        throw refreshError;
      }
    }
    // If the error is not due to an expired token, reject the promise with the error
    return Promise.reject(error);
  }
);

export { privateAxiosInstance, axiosInstance };
