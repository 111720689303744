import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import Advisor from "./_Advisor";
import Button from "components/ui/Button/Button";
import Select from "components/ui/Select/Select";
import SwitchButton from "components/ui/SwitchButton/SwitchButton";
import ListRenderer from "components/utility/ListRenderer";
import PageContainer from "components/ui/PageContainer/PageContainer";
import DataRenderer from "components/utility/DataRenderer";
import { API_ENDPOINTS, COLORS, GENERIC_ERROR_RES_MSG } from "utils/constants";

import {
  StyledAlignCenter,
  StyledCentering,
} from "styles/common-styled-components";

const initialErrorState = { sortByNearest: "" };

const Home = () => {
  const [sortingQueryParam, setSortingQueryParam] = useSearchParams("");
  const [errors, setErrors] = useState(initialErrorState);
  const [loadingCoords, setLoadingCoords] = useState(false);

  // Getting Query Dependencies
  const queryParamsObject = Object.fromEntries(sortingQueryParam.entries());
  const queryParamValues = Object.values(queryParamsObject);

  const getLocationCoords = async () => {
    try {
      setLoadingCoords(true);
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const coords = {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      };

      setErrors(initialErrorState);
      return coords;
    } catch (error) {
      setErrors({ sortByNearest: GENERIC_ERROR_RES_MSG });
      return null;
    } finally {
      setLoadingCoords(false);
    }
  };

  const handleSortByNearest = async () => {
    if (!sortingQueryParam.get("nearest")) {
      try {
        setLoadingCoords(true);
        const coords = await getLocationCoords();

        if (coords) {
          const lat_long = `${coords.lat},${coords.long}`;
          setSortingQueryParam({ nearest: lat_long });
        }
      } finally {
        setLoadingCoords(false);
      }
    } else {
      setSortingQueryParam({});
    }
  };

  return (
    // Logout
    <PageContainer>
      <StyledCentering
        style={{
          flexDirection: "column",
          color: COLORS.mediumGrey,
          marginBottom: "6rem",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            width: "50%",
            color: COLORS.mediumGrey,
            fontSize: "3rem",
            marginBottom: "4rem",
          }}
        >
          Expert Advice, Zero Hassle
          <br /> Your Direct Path to Informed Decisions
        </h1>
        <p style={{ marginBottom: "1rem" }}>
          <span style={{ color: COLORS.secondaryLightGrey }}>--------</span>
          &nbsp;Are you an Advisor?&nbsp;
          <span style={{ color: COLORS.secondaryLightGrey }}>--------</span>
        </p>
        <Button title={"Create Advisor account"} />
      </StyledCentering>

      <StyledAlignCenter
        style={{
          gap: "2rem",
        }}
      >
        <div>
          <label>Sort by rating</label>

          <Select
            value={sortingQueryParam.get("ordering")}
            options={[
              { value: "", label: "Default" },
              { value: "-rating", label: "High to Low" },
              { value: "rating", label: "Low to High" },
            ]}
            onChange={(v) => {
              setSortingQueryParam({ ordering: v });
            }}
          />
        </div>
        <SwitchButton
          title={"Sort by Nearest"}
          onClick={handleSortByNearest}
          isLoading={loadingCoords}
          error={errors.sortByNearest}
          isActive={Boolean(sortingQueryParam.get("nearest"))}
        />
      </StyledAlignCenter>

      <DataRenderer
        apiEndpoint={API_ENDPOINTS.advisors}
        qParams={sortingQueryParam}
        queryDependencies={queryParamValues}
        queryName={"advisors"}
        renderData={(advisorsList) => {
          console.log(advisorsList);
          return (
            <ListRenderer
              items={advisorsList.data}
              renderItem={(advisor) => <Advisor data={advisor} />}
            />
          );
        }}
      />
    </PageContainer>
  );
};

export default Home;
