import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Auth = () => {
  const navigate = useNavigate();

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (userDetails?.mobile_number) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Auth;
