import React, { useState } from "react";
import { useQuery } from "react-query";

import ErrorBoundary from "./ErrorBoundary";
import {
  axiosInstance,
  privateAxiosInstance,
} from "api-calls/req-res-instance";
import { COLORS, GENERIC_ERROR_RES_MSG } from "utils/constants";
import { BeatLoader } from "react-spinners";

import { StyledCentering } from "styles/common-styled-components";

const defaultRenderLoading = () => (
  <StyledCentering style={{ width: "100%", height: "10rem" }}>
    <BeatLoader color={COLORS.primary} />
  </StyledCentering>
);

// Default renderError function
const defaultRenderError = ({ error }) => (
  <div>
    <p>{GENERIC_ERROR_RES_MSG}</p>
  </div>
);

const DefaultErrorFallback = () => (
  <div>
    <p>Oops! Something went wrong.</p>
  </div>
);

const DataRenderer = ({
  enabled = true,
  apiEndpoint,
  qParams,
  privateRequest = false,
  queryName,
  queryDependencies = [],
  onSuccess = () => {},
  onError = () => {},
  renderData,
  renderLoading = defaultRenderLoading,
  renderError = defaultRenderError, // Provide a default renderError function
  errorFallback: ErrorFallbackComponent = DefaultErrorFallback,
}) => {
  const [error, setError] = useState("");

  const handleFetching = () => {
    if (privateRequest) {
      return privateAxiosInstance.get(apiEndpoint, { params: qParams });
    }
    return axiosInstance.get(apiEndpoint, { params: qParams });
  };

  const { isLoading, data } = useQuery(
    [queryName, ...queryDependencies],
    handleFetching,
    {
      onSuccess: () => {
        setError("");
        onSuccess();
      },
      onError: () => {
        setError(GENERIC_ERROR_RES_MSG);
        onError();
      },
      enabled,
    }
  );

  return (
    <ErrorBoundary fallback={<ErrorFallbackComponent />} error={error}>
      {isLoading ? (
        renderLoading()
      ) : error ? (
        renderError({ error })
      ) : (
        <ErrorBoundary fallback={<ErrorFallbackComponent />}>
          {renderData(data, error)}
        </ErrorBoundary>
      )}
    </ErrorBoundary>
  );
};

export default DataRenderer;
