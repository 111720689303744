import React from "react";
import { AnimatePresence } from "framer-motion";

import RoutesComp from "./_RoutesComp";
import { QueryClient, QueryClientProvider } from "react-query";
import Navbar from "./components/global/Navbar/Navbar";
import GlobalStyles from "styles/global-styles";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <AnimatePresence mode="wait">
        <div style={{ padding: "12rem", marginTop: "2rem" }}>
          <RoutesComp />
        </div>
      </AnimatePresence>

      <Navbar />
    </QueryClientProvider>
  );
};

export default App;
