import React from "react";

import ListRenderer from "components/utility/ListRenderer";

const Table = ({ data }) => {
  return (
    <table>
      <tbody>
        <ListRenderer
          items={Object.entries(data)}
          renderItem={([heading, value]) => (
            <tr key={heading}>
              <td>{heading}</td>
              <td>{value}</td>
            </tr>
          )}
        />
      </tbody>
    </table>
  );
};

export default Table;
